import React, { useEffect } from "react";

export default function Input({
  callback,
  children,
  label,
  handleChange,
  description,
  error,
  tip,
  isOk,
  limited,
  spaces,
  ...props
}) {
  useEffect(() => {
    callback && callback(props.value);
    return () => {};
  }, [props.value]);

  const onChange = (el) => {
    let target = el;
    if (limited) {
      const newVal = el.target.value
        .replace(/[^\w\s]/gi, "")
        .split(" ")
        .join("");
      if (newVal == props.value) return;
      target.target.value = newVal;
    }
    handleChange(target);
  };

  return !props.readOnly ? (
    <label
      className={`label input ${props.value ? "active" : ""} ${
        error ? "error" : ""
      } ${props.value && isOk ? "success" : ""}`}
    >
      {tip && (
        <div className="top_tip df ai-c jc-st">
          <p className="fixel_14">{tip.title}</p>

          {tip?.tip && (
            <>
              <div
                className="icon"
                onClick={({ target }) =>
                  target.closest(".icon").classList.toggle("active")
                }
              ></div>
              <p className="info fixel_14">{tip?.tip}</p>
            </>
          )}
        </div>
      )}
      {label || (error && error?.length >= 1) ? (
        <div
          className="name fixel_14"
          dangerouslySetInnerHTML={{ __html: error || label }}
        ></div>
      ) : null}
      <div className="row">
        <input
          onChange={onChange}
          {...props}
          value={props.value}
          {...(spaces
            ? {
                onKeyDown: (e) => {
                  (e.code == "code" || e.key == " ") && e.preventDefault();
                },
              }
            : {})}
        />
        <div className="border"></div>
        {children}
      </div>
      {description && (
        <p className="fixel_14 label_description">{description}</p>
      )}
    </label>
  ) : (
    <label className={"label read"}>
      {label || error ? (
        <div className="name fixel_14">{label || error}</div>
      ) : null}
      <div className="row">
        <input {...props} />
        <div className="border"></div>
        {children}
      </div>
    </label>
  );
}
