import React, { useEffect, useState } from "react";

import useForm from "/utils/hooks/useForm";
import FormLabels from "/components/core/labels/Form";
import CustomButton from "/components/core/ButtonDefault";

const labels = [
  {
    placeholder: "Enter your subject",
    name: "subject",
  },
  {
    placeholder: "Your message",
    type: "textarea",
    name: "message",
  },
];

const success = {
  title: "Your message has been sent",
  subtitle: "We will contact you as soon as possible",
  close: "Okay",
};

export default function Write({ state }) {
  const useform = useForm({}, "user/contact");
  const { isSuccess, isSending, handleSubmit } = useform;

  useEffect(() => {
    isSuccess && state.setPopUp("success", success);
    return () => {};
  }, [isSuccess]);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">Write to us</p>
      </div>

      <div className="content_data df fd-c">
        <form onSubmit={handleSubmit} className="form df fd-c">
          <div className="form_content">
            <FormLabels {...useform} labels={labels} />
          </div>

          <CustomButton
            classname="submit pr as-c"
            def={40}
            type="submit"
            title=" Send request"
            disabled={isSending}
          />
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">Or chat with us in</p>
        <button className="def wt">Discord community</button>
      </div>
    </>
  );
}
