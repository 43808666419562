import React, { useEffect, useRef, useState } from "react";

import FormLabels from "/components/core/labels/Form";
import useForm from "/utils/hooks/useForm";
import CustomButton from "/components/core/ButtonDefault";
import Recaptcha from "react-google-invisible-recaptcha";
import { useSearchParams } from "react-router-dom";
import useAxiosREST from "/utils/hooks/useAxiosAPI";
import Hidden from "/components/core/labels/Hidden";
import { useConversionParams } from "/context/ConversionParamsProvider";

const siteKey =
  import.meta.env.NODE_ENV == "development"
    ? "6Lfvra8oAAAAAAJR0pNfaCYjJSSA3hcMa5ZabnWD"
    : "6Lc-m_IpAAAAAJWzg5anOMnKpqdW3JQh2wzi3zWk";

const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const passErrMin = "Password must be at least 8 characters long";
const passErr =
  "Password must contain at least one uppercase letter, lowercase letter, number and special character.";
const passAgainErr = "The passwords must match";

const emailError = ["Please enter a valid email address"];
const emailExist = ["Email address already in use"];

export default function RegistrationLabels({
  setSuccess,
  respons,
  state,
  classList = "",
}) {
  const useform = useForm({}, "auth/register");
  const reCaptcha = useRef(null);
  const { fetchAPI } = useAxiosREST();

  const {
    isSuccess,
    formResponse,
    isSending,
    handleSubmit,
    setInvalidFields,
    formData,
  } = useform;

  const { password, passwordAgain, name, email } = formData;

  const [countries, setCountries] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ref_url, setRefUrl] = useState(document.referrer || location.href);
  const formRef = useRef(null);
  const [isPassOk, setIsPassOk] = useState(false);
  const [isNameOk, setIsNameOk] = useState(false);
  const [isEmailOk, setIsEmailOk] = useState(false);
  const [isConfirmPassOk, setIsConfirmPassOk] = useState(false);
  const [referal, setReferal] = useState(null);
  const [initReC, setInitReC] = useState(false);
  const conversionParams = useConversionParams();
  const [connectLuckyFunaticCode, setConnectLuckyFunaticCode] = useState(null);

  const labels = [
    {
      placeholder: "Username",
      name: "name",
      limited: true,
      autoComplete: "false",
      description:
        "This username will be secured on the Funtico platform when it launches and will be tied to the email provided in this form.",
      required: true,
      maxLength: 15,
      isOk: isNameOk,
      minLength: 3,
      tip: {
        title: "Username",
        tip: "Your chosen username must be between 3 and 15 characters long and may include only underscores and numbers",
      },
    },
    {
      placeholder: "Enter your e-mail",
      type: "email",
      name: "email",
      autoComplete: "false",
      required: true,
      spaces: true,
      isOk: isEmailOk,
      tip: {
        title: "E-mail",
      },
    },
    {
      placeholder: "Enter your password",
      type: "password",
      autoComplete: "false",
      name: "password",
      minLength: 8,
      required: true,
      isOk: isPassOk,
      tip: {
        title: "Password",
        tip: "Your chosen password must be at least 8 characters long and it should include an Upper Case, a lower case, at least 1 number and a special character (!, @, #, etc.)",
      },
    },
    {
      placeholder: "Repeat your password",
      type: "password",
      name: "passwordAgain",
      autoComplete: "false",
      required: true,
      isOk: isConfirmPassOk,
      tip: {
        title: "Repeat password",
      },
    },
    {
      placeholder: "Enter your country",
      type: "select",
      name: "country",
      search: true,
      autoComplete: "false",
      required: true,
      tip: {
        title: "Country",
      },
    },
    // {
    //   placeholder: "Enter your referral code ",
    //   name: "referral",
    //   ...referal,
    //   max: 200,
    // },
    { title: "I am 18 years or older", type: "checkbox", name: "older" },
    {
      type: "checkbox",
      name: "agree",
    },
    {
      title: "Receive updates about Funtico games, tournaments, and promotions",
      type: "checkbox",
      name: "allow_email",
      def: true,
      underline: true,
    },
  ];

  useEffect(() => {
    const luckyFunaticCode = searchParams.get("lf_connect");
    setConnectLuckyFunaticCode(luckyFunaticCode);
    const getCountries = async () => {
      const data = await fetchAPI.get("countries");
      setCountries(data.data);
    };

    const getReferal = () => {
      const btag = searchParams.get("btag") || conversionParams.btag;

      if (btag) {
        setReferal(btag);
        // for (const name of searchParams.keys()) {
        //   // searchParams.delete(name);
        // }
        // setSearchParams("");
      }
    };

    setTimeout(() => setInitReC(true), 5000);

    getReferal();
    getCountries();
    return () => {};
  }, []);

  useEffect(() => {
    setSuccess && setSuccess(isSuccess);
    isSuccess &&
      state.setPopUp("success", {
        title: "VERIFICATION REQUIRED",
        subtitle: "A verification link has been sent to your email account.",
        close: "Okay",
      });

    return () => {};
  }, [isSuccess]);

  useEffect(() => {
    respons && respons(formResponse);
    return () => {};
  }, [formResponse]);

  useEffect(() => {
    if (!password && !passwordAgain) return;

    let st = setTimeout(validatePass, 300);
    return () => {
      clearTimeout(st);
    };
  }, [password, passwordAgain]);

  useEffect(() => {
    if (!name) return;

    setIsNameOk(false);
    let st = setTimeout(validateName, 100);
    return () => {
      clearTimeout(st);
    };
  }, [name]);

  useEffect(() => {
    if (!email) return;
    setIsEmailOk(false);
    let st = setTimeout(validateEmail, 100);
    return () => {
      clearTimeout(st);
    };
  }, [email]);

  const validatePass = () => {
    let ress = { ok: true };
    let err = null;

    if (
      password?.length &&
      passwordAgain?.length &&
      password !== passwordAgain
    ) {
      setIsConfirmPassOk(false);
      err = { passwordAgain: [passAgainErr] };
      ress.ok = false;
    } else password && passwordAgain && setIsConfirmPassOk(true);

    if (password?.length < 8 || !passwordRegex.test(password)) {
      setIsPassOk(false);
      err = { password: [password?.length < 8 ? passErrMin : passErr] };
      ress.ok = false;
    } else password && setIsPassOk(true);
    err && (ress.error = err);

    !ress.ok && setInvalidFields((e) => ({ ...ress.error, ...e }));

    return ress.ok;
  };

  const validateName = async () => {
    // const value = formData["name"];

    if (name?.length < 3) {
      setInvalidFields((e) => ({
        name: ["Username must be between 3 and 15 characters long"],
      }));
      return;
    }
    const d = new FormData();
    d.append("nickname", name);
    const data = await fetchAPI.post("verify/nickname", d);
    data &&
      setInvalidFields((e) => ({ name: "This username is already taken" }));
    setIsNameOk(!data);
  };

  const validateEmail = async () => {
    const input = formRef.current.querySelector(`[name='email']`);
    const isValid = input.checkValidity() && emailRegex.test(email);

    if (!isValid) {
      setInvalidFields((e) => ({ ...e, ...{ email: emailError } }));
      return;
    }
    const d = new FormData();
    d.append("email", email);
    const data = await fetchAPI.post("verify/email", d);
    data && setInvalidFields((e) => ({ ...e, ...{ email: emailExist } }));
    setIsEmailOk(!data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    const isOk = validatePass();

    if (isOk && isEmailOk && isNameOk) {
      const data = new FormData(e.target);
      window.regg_mail = data.get("email");
      reCaptcha?.current?.execute().then((res) => {
        data.append("recaptcha", res);
        handleSubmit(data);
      });
    } else {
      let err;
      setInvalidFields((e) => {
        err = e;
        return {};
      });
      setTimeout(() => setInvalidFields({ ...err }), 300);
    }
    setTimeout(() => setIsSubmitDisabled(false), 300);
  };

  return (
    <>
      <form
        className={"form df fd-c" + classList}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <div className="form_content">
          <FormLabels {...useform} labels={labels} selectData={countries} />
          {conversionParams.tfid && (
            <Hidden name="dmission_user_id" value={conversionParams.tfid} />
          )}
          {conversionParams.tftarget && (
            <Hidden name="dmission_task_id" value={conversionParams.tftarget} />
          )}
          <Hidden name="referral_url" value={ref_url} />
          {connectLuckyFunaticCode ? (
            <Hidden name="lf_connect" value={connectLuckyFunaticCode} />
          ) : null}
          <Hidden name="page" value={"gaming"} />
          {referal && <Hidden name="btag" value={referal} />}
        </div>
        <div className="buttons df mr-t-46">
          <CustomButton
            classname="submit pr as-c "
            def={40}
            type="submit"
            title="Register"
            disabled={
              isSubmitDisabled ||
              isSending ||
              !useform.formData?.["agree"] ||
              !useform.formData?.["older"]
            }
          />
        </div>
        {Object.keys(useform.invalidFields).length == 1 &&
        useform.invalidFields?.["recaptcha"] ? (
          <div className="error_form mr-t-24 fixel_16_md">
            {useform.invalidFields?.["recaptcha"]}
          </div>
        ) : null}
      </form>

      <div className="hidden_recaptcha">
        {initReC && <Recaptcha ref={reCaptcha} sitekey={siteKey} />}
      </div>
    </>
  );
}
