import React, { useContext, useEffect } from "react";

import Password from "/components/core/labels/Password";
import CustomButton from "/components/core/ButtonDefault";
import useForm from "/utils/hooks/useForm";
import FormLabels from "/components/core/labels/Form";
import AuthContext from "/context/AuthProvider";

const labels = [
  {
    placeholder: "Enter your e-mail",
    type: "email",
    name: "email",
  },
];

export function ResetPassword({ state }) {
  const useform = useForm({}, "auth/forgot");
  const { isSending, formResponse, handleSubmit } = useform;
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (formResponse?.data?.expires && !formResponse?.errors) {
      state.setPopUp("confirm", {
        title: "Password reset successfully.",
        subtitle: "We sent a link to your email to create a new password.",
        close: "Okey",
      });
    }

    return () => {};
  }, [formResponse]);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">Forgot password</p>
        <p className="s">
          Enter your email and we will send you a link to reset your password
        </p>
      </div>

      <div className="content_data df fd-c">
        <form className="form df fd-c" onSubmit={handleSubmit}>
          <div className="form_content">
            <FormLabels {...useform} labels={labels} />
          </div>
          <CustomButton
            type="submit"
            classname="submit pr as-c"
            def={40}
            title={"Confirm"}
            disabled={isSending}
          />
        </form>
      </div>
      {!auth?.token && (
        <div className="switch_form jc-c fw as-c df">
          <p className="fixel_16_sbl">No access to e-mail?</p>
          <button className="def wt" onClick={(e) => state.setPopUp("reg")}>
            Create new account
          </button>
        </div>
      )}
    </>
  );
}

export function CreatePassword({ state }) {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">Create new password</p>
        <p className="s">
          Your password has been successfully reset. Create and enter a new
          password
        </p>
      </div>

      <div className="content_data df fd-c">
        <form className="form df fd-c">
          <div className="form_content">
            <Password placeholder="Enter password" />
            <Password placeholder="Confirm password" />
          </div>
          <button className="def submit type-40 pr as-c br-16">Confirm</button>
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">Or chat with us in</p>
        <button className="def wt">Discord community</button>
      </div>
    </>
  );
}
