import React, { useEffect } from "react";

import Input from "/components/core/labels/Input";

export default function Verify({ state }) {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">Verify your Identity</p>
      </div>

      <div className="content_data df fd-c">
        <form className="form df fd-c">
          <div className="form_content">
            <Input placeholder="Enter code" />
          </div>

          <div className="switch_form jc-c fw as-c df">
            <p className="fixel_16_sbl">You don’t have code?</p>
            <button className="def wt" type="button">
              Sent again
            </button>
          </div>
          <button className="def submit type-40 pr as-c br-16">Confirm</button>
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">Don’t you have a message in your email?</p>
        <button className="def wt" onClick={(e) => state.setPopUp("write")}>
          Write to us
        </button>
      </div>
    </>
  );
}
