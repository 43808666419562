import React from "react";
import KYC from "./components/Deposit-KYC";
import Flow from "./components/Deposit-Flow";
import { KYCStatus } from "/api/user/kyc-status";

/**
 * 
 * @param {{
 *  kycStatus: import("/api/user/kyc-status").UseKycStatus
 *  onClose: () => void
 * }} props 
 * @returns 
 */
export default function Deposit({ kycStatus, ...props}) {
  if (kycStatus.isLoading) return (<div>loading...</div>);
  if (KYCStatus.isUserVerified(kycStatus.data?.kyc_status)) 
    return <Flow kycStatus={kycStatus} {...props} />;

  return <KYC kycStatus={kycStatus} {...props} />;
}
