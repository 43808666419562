import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useEffect } from "react";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey:
    import.meta.env.VITE_ENVIRONMENT === "production"
      ? "sdk-hMYdrBTIA5ADYoyz"
      : "sdk-tAYzjAmyPjhMnBcJ",
  enableDevMode: import.meta.env.VITE_ENVIRONMENT !== "production",
});

export default function FeatureFlagsProvider({ children }) {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.init({ streaming: true });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
}
