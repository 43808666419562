// import { debounce } from "./hooks/use-EventListener";

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

let resizeObserverInstance = null;
let observing = {};

const onResize = debounce((entries) => {
  for (let entry of entries) {
    const callback = observing[entry.target]?.callback;
    if (callback) callback(entry);
  }
}, 200);

const init = () => {
  if (!resizeObserverInstance) {
    resizeObserverInstance = new ResizeObserver(onResize);
  }
};

function add(element, callback) {
  init();

  if (typeof callback === "function") {
    observing[element] = { element, callback };
    resizeObserverInstance.observe(element);
  } else {
    console.error("Provided callback is not a function");
  }
}

function remove(element, callback) {
  if (observing[element] && observing[element].callback === callback) {
    resizeObserverInstance.unobserve(element);
    delete observing[element];
  } else {
    console.warn("Element not found in observed list or callback mismatch");
  }
}

function destroy() {
  if (resizeObserverInstance) {
    resizeObserverInstance.disconnect();
    observing = {};
    resizeObserverInstance = null;
  }
}

export default {
  add,
  remove,
  destroy,
};
