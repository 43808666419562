import React, { useEffect, useState } from "react";
import Input from "/components/core/labels/Input";

import CustomButton from "/components/core/ButtonDefault";
import SelectCurrency from "../SelectCurrency";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import currencies from "../data/fiat-currencies.json";
import { Receive } from "./receive/index";
import { ProvidersControlled } from "./ProvidersControlled";
import {
  getValidProviders,
  makeCurrency,
  materializeCurrency,
  validate,
} from "./Currency";
import Transaction from "./Crypto-Transaction";
import { useCryptoAvailableCurrencies } from "/api/cashier/crypto/available-currencies";
import { useEstimatedExchangeResult } from "/api/cashier/crypto/estimated-exchange-result";

window["crypto-currencies"] = currencies; //TODO

const labelPlay = {
  placeholder: "Enter Amount",
  name: "amount",
  type: "number",
  autoComplete: "false",
  required: true,
  tip: { title: "I want to pay" },
};

const labelBonus = {
  placeholder: "Enter Bonus Code",
  name: "amount",
  autoComplete: "false",
  required: true,
  tip: { title: "Bonus Code (Optional)" },
};

/**
 * Error is ok, min/max can be shapeless
 * @type {import("./types.js").Currency}
 */
const defaultCurrency = makeCurrency({ code: "AVAX", providers: [] });

export default function Crypto(props) {
  const { back, onClose, state, cryptoExs } = props;
  const [amount, setAmount] = useState("");
  const [bonusCode, setBonusCode] = useState("");
  const [isProviders, setIsProviders] = useState(false);
  const axiosClient = useAxiosWithAuth();
  const [transactionData, setTransactionData] = useState(null);

  // 1. Get supported fiat currencies with min max range.
  // Cashier act as provider and provide the min/max range, that acts as higher priority range.
  const [currency, setCurrency] = useState(defaultCurrency);
  const validProviders = getValidProviders({ amount, currency });

  const availableCurrencies = useCryptoAvailableCurrencies(
    axiosClient,
    {
    }
  );
  if (currency === defaultCurrency && availableCurrencies.data) {
    console.log("onSuccess setCurrency", currency, defaultCurrency);
    // if it's default then set the real currency instead
    setCurrency(materializeCurrency(availableCurrencies.data, currency.code));
  }
  const bestRate = useEstimatedExchangeResult(
    axiosClient,
    validProviders,
    currency,
    amount,
    {
      refreshInterval:
        isProviders && validProviders.length !== 0 ? undefined : 1000,
    }
  );
  const [amountError, setAmountError] = useState(null);

  const goToProviders = () => {
    setIsProviders(true);
  };
  useEffect(() => {
    validateAmount();
    // Show error if have min/max
    function validateAmount() {
      const err = validate({ amount, currency });
      if (err) {
        setAmountError(err);
      } else if (
        !bestRate.isValidating &&
        !bestRate.isLoading &&
        bestRate.error
      ) {
        // todo: show real error, not axios error
        setAmountError(bestRate.error.message || "Try, another amount");
      } else {
        setAmountError();
      }
    }
  }, [
    amount,
    currency,
    bestRate.error,
    bestRate.isValidating,
    bestRate.isLoading,
  ]);

  if (isProviders && validProviders.length !== 0) {
    return (
      <ProvidersControlled
        data={{
          validProviders,
          currency,
          amount,
        }}
        selected={bestRate.data}
        onPay={({ response }) => {
          //close Wallet popup
          setTransactionData(response);
          setIsProviders(false);
          // show popup on Success
          // state.setPopUp("confirm", {
          //   title: "Purchase Completed",
          //   subtitle:
          //     "Your transfer has been received! <br> <br> You will be notified as soon as the transaction has been successfully confirmed!",
          //   close: "Let’s Play!",
          // });
        }}
        back={() => setIsProviders(false)}
      >
        <div className="crypto-exs df fd-c ai-c gap-16" data-hide-tabs>
          <p className="fixel_22">Also listed on:</p>
          <div className="df">
            {cryptoExs.map((ex) => (
              <div className={"icon df icon_" + ex.icon} key={ex.icon} />
            ))}
          </div>
        </div>
      </ProvidersControlled>
    );
  }

  if (transactionData) {
    return <Transaction {...props} {...transactionData} />;
  }

  return (
    <>
      <div className="buy-fiat df fd-c">
        <div className="row-amount df ai-st">
          <Input
            {...labelPlay}
            // min="0.00"
            // max="10000.00"
            // step="0.01"
            value={amount}
            error={amountError}
            handleChange={(e) => {
              if (e.target.value !== amount) {
                setAmount(e.target.value);
              }
            }}
          />
          <SelectCurrency
            disabled={availableCurrencies.isLoading}
            currencys={(availableCurrencies.data ?? [])
              .map((c) => c.code)
              .sort()}
            value={currency.code}
            onChange={(x) => {
              console.log("setCurrency", x);
              setCurrency(materializeCurrency(availableCurrencies.data, x));
            }}
            state={props.state}
            upper={true}
          />
        </div>
        <Receive
          isLoading={bestRate.isLoading}
          data={bestRate.data}
          currency={currency}
        />
      </div>

      <Input
        {...labelBonus}
        value={bonusCode}
        handleChange={(e) => setBonusCode(e.target.value)}
      />

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={back}
          classname={`go-back grey`}
          def={40}
          title={"Cancel"}
        />
        <CustomButton
          onClick={goToProviders}
          classname={`go-buy pr`}
          def={40}
          disabled={!amount || amountError || validProviders.length === 0}
          title={"Buy"}
        />
      </div>
    </>
  );
}
