import useSWRMutation from "swr/mutation";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI.js";
import Providers from "../providers/Providers.jsx";
import React from "react";
import { useEstimatedExchangeResultAll } from "/api/cashier/crypto/estimated-exchange-result-for-all-providers.js";
import { useCreatePaymentSession } from "/api/cashier/crypto/providers/create-payment-session.js";

/**
 *
 * @param {{
 * data: { validProviders: import("./types.js").CurrencyProviderRange[], currency: import("./types.js").Currency, amount: string },
 * selected: import("./types.js").BestRate | undefined,
 * onPay: (arg0: { quote_id: string, response: { message: "Payment initialized" } }) => void
 * back: () => void,
 * children?: React.ReactNode
 * }} param0
 * @returns
 */
export function ProvidersControlled({
  data: { validProviders, currency, amount },
  selected,
  onPay,
  back,
  children,
}) {
  if (validProviders.length === 0) {
    throw new Error("Should be called only with valid providers");
  }
  const axiosClient = useAxiosWithAuth();
  const rates = useEstimatedExchangeResultAll(
    axiosClient,
    validProviders,
    currency,
    amount,
    {
      fallbackData: selected ? [selected] : [],
      refreshInterval: 25000,
    }
  );

  const pay = useCreatePaymentSession(
    axiosClient,
    {
      onSuccess: (data, key, config) => {
        console.log("pay onSuccess", data, key, config);
      },
    }
  );

  /**
   *
   * @param {import("./types.js").BestRate} param0
   */
  const toConfirm = async ({ provider, response: { from } }) => {
    // const response = {
    //   message: "Payment initialized",
    // };
    try {
      /**
       * @type {{ message?: "Payment initialized" }}
       */
      const response = await pay.trigger({
        fromCurrencyCode: from.code,
        fromCurrencyAmount: from.amount,
        provider,
      });

      if (response.message) {
        console.debug("toConfirm", response);
        onPay({ response });
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (rates.isLoading) {
    if (rates.data) {
      return (
        <Providers
          providers_data={rates.data}
          selected={selected}
          back={back}
          toConfirm={toConfirm}
          getProviders={() => {
            rates.mutate();
          }}
        />
      );
    }
    // initial state should be set
    // if u see loading => it's a bug
    return <div>Loading...</div>;
  }
  if (rates.error) {
    console.error(rates.error);
    return <div>Error...</div>;
  }

  return (
    <Providers
      providers_data={rates.data}
      selected={selected}
      back={back}
      toConfirm={toConfirm}
      getProviders={() => {
        rates.mutate();
      }}
    >
      {pay.error && ((typeof pay.error === 'object') ? pay.error?.message : pay.error)}
      {children}
    </Providers>
  );
}
