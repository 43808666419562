import { useState, useContext } from "react";
import AuthContext from "/context/AuthProvider";
import useAxiosREST from "./useAxiosAPI";

export default function useForm(
  initialState = {},
  endpoint,
  metod = "",
  clearAfterSuccess = true
) {
  const { auth, setAuth } = useContext(AuthContext);
  const [formData, setFormData] = useState(initialState);

  const [invalidFields, setInvalidFields] = useState({});
  const [isSending, setSending] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [formResponse, setResponse] = useState(null);
  const { fetchAPI } = useAxiosREST();

  const getValue = ({ type, checked, value, files }) => {
    switch (type) {
      case "checkbox":
        return checked ? true : false;
      case "file":
        return files[0] ?? null;
      default:
        return value.replace(/\s\s+/g, " ");
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;

    const value = getValue(event.target);
    const data = formData;

    if (value) {
      data[name] = value;
    } else {
      delete data[name];
    }
    setFormData({ ...data });

    // setFormData({ ...formData, [name]: getValue(event.target) });
    if (!invalidFields[name]) return;
    const i = invalidFields;
    delete i[name];
    setInvalidFields({ ...i });
  };

  const onSuccess = () => {
    setInvalidFields({});
    clearAfterSuccess && setFormData({});
    setSuccess(true);
  };

  const handleSubmit = async (event, url = endpoint, exceptions = null) => {
    setSending(true);
    let data = event;
    let response;

    if (data?.target) {
      data.preventDefault();
      data = new FormData(data.target);
    }

    for (const pair of data.entries()) {
      if (pair[1] == "on") {
        data.set(pair[0], 1);
      }
    }

    !exceptions && setInvalidFields({});
    setSuccess(false);

    switch (metod) {
      case "patch":
        response = await fetchAPI.patch(url, data);
        break;
      case "delete":
        response = await fetchAPI.delete(url, data);
        break;
      case "put":
        response = await fetchAPI.put(url, data);
        break;
      default:
        response = await fetchAPI.post(url, data);
        break;
    }
    if (response?.success || response.message == "ok") {
      onSuccess(response);
    }
    if (response?.errors) {
      if (exceptions) {
        let errs = {};
        exceptions.forEach((name) => {
          const error = response.errors?.[name];
          error && (errs[name] = error);
        });
        setInvalidFields((e) => ({ ...e, ...errs }));
      } else setInvalidFields(response?.errors);
    }
    setResponse(response);
    setSending(false);
  };

  return {
    formData,
    formResponse,
    invalidFields,
    isSuccess,
    isSending,
    setInvalidFields,
    setFormData,
    handleChange,
    handleSubmit,
  };
}
