import React, { useEffect, useState } from "react";
import useAxiosREST from "/utils/hooks/useAxiosAPI";
import LoadImage from "/components/core/LazyImage";
import DelayLink from "/components/core/DelayLink";
import checkURL from "/utils/checkURL";

export default function RecentlyGames({ mobile }) {
  const [games, setGames] = useState([]);
  const { fetchAPI } = useAxiosREST();

  const getSingleGame = async () => {
    if (auth?.token) {
      const data = await fetchAPI.get("games/" + id);
      window.open(data.data.iframe, "_blank");
    } else state.setPopUp("login");
  };

  const getFetch = async () => {
    const data = await fetchAPI.get("games/recent");
    data?.data && setGames(data.data);
  };
  useEffect(() => {
    getFetch();

    return () => {};
  }, []);

  return games.length ? (
    <>
      <div className="drid df fd-c">
        <p className="title txt">Recently Played</p>
        <div className="column df fd-c recently">
          {games.map((game, id) => (
            <DelayLink
              key={id}
              to={"/games/" + game.id}
              className="link df ai-c"
              title={game.name}
            >
              <div className="ic df br-8 cover">
                <LoadImage src={checkURL(game.image)} />
              </div>
              {!mobile ? <div className="txt">{game.name}</div> : null}
            </DelayLink>
          ))}
        </div>
      </div>
    </>
  ) : null;
}
