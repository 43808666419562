import React, { useState } from "react";

import CustomButton from "/components/core/ButtonDefault";
import { ProgressBar } from "./ProgressBar";
import { ProviderRow } from "./ProviderRow";

const refreshBy = 20;

/**
 * @param {{ toConfirm: (x: import("../buy-fiat/types").BestRate) => void, children?: any, getProviders: () => void, back: () => void, providers_data: import("../buy-fiat/types").BestRate[],  selected: import("../buy-fiat/types").BestRate }} param0
 */
export default function Providers({
  back,
  toConfirm,
  children,
  getProviders: revalidate,
  providers_data,
  selected,
}) {
  const [selectedProvider, setSelectedProvider] = useState(selected.provider);
  const materializedProvider = providers_data.find(
    (x) => x.provider === selectedProvider
  );

  return (
    <>
      <div className="row-providers">
        {providers_data
          .filter((x) => x.type === "success")
          .map((lineItem) => (
            <ProviderRow
              key={lineItem.provider}
              onClick={() => setSelectedProvider(lineItem.provider)}
              selected={selectedProvider}
              data={lineItem}
            />
          ))}
      </div>

      {children}

      <ProgressBar onComplete={revalidate} duration={refreshBy} />
      <div className="buttons df jc-sb">
        <CustomButton
          onClick={back}
          classname={`go-back grey`}
          def={40}
          title={"Cancel"}
        />
        <CustomButton
          disabled={!selectedProvider || !materializedProvider}
          onClick={() => {
            toConfirm(materializedProvider);
          }}
          classname={`go-buy pr`}
          def={40}
          title={"Confirm"}
        />
      </div>
    </>
  );
}
