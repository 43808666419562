import useSWRImmutable from 'swr/immutable'

/**
 * 
 * @typedef {{
                    chainId: number,
                    chainName: string,
                    chainIcon: string | null,
                    requiredConfirmations: number,
                    addressType: string | null
    }} AvailableChain
  @typedef {{ 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }} Error
 * This address should be gained only after the user has selected the chain manually
 * It make sense to use trusted event to trigger this request, but for now it's fine
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr").SWRConfiguration<AvailableChain[], Error, import("swr").BareFetcher<AvailableChain[]>>} config
 * @return {import("swr").SWRResponse<AvailableChain[], Error>}
 */
export function useAvaliableChains(axiosClient, config) {
  return useSWRImmutable(
    ['cashier/withdrawal/available-chains'],
    ([url]) => axiosClient.wrap(axiosClient.get(url)),
    config
  )
}
