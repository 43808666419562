import React from "react";
export default function SVGGradient({ mobile }) {
  return (
    <>
      <svg className="svg_hidden">
        <clipPath id="funtico_logo" clipPathUnits="objectBoundingBox">
          <path d="M0.649,0.962 l-0.048,0,0.032,-0.922,0.048,0,-0.032,0.922 m0.333,-0.824 c-0.027,-0.19,-0.075,-0.181,-0.106,0.02 c-0.032,0.2,-0.036,0.517,-0.009,0.707 c0.027,0.19,0.075,0.18,0.106,-0.02 c0.032,-0.2,0.036,-0.517,0.009,-0.706 m-0.018,0.375 c0,0.006,0,0.011,-0.001,0.017 c0,0.005,-0.001,0.009,-0.002,0.011 l-0.058,0.197 c-0.001,0.003,-0.002,0.004,-0.003,0.004 c-0.001,-0.001,-0.002,-0.003,-0.003,-0.007 c-0.001,-0.004,-0.001,-0.01,-0.002,-0.016 c0,-0.006,0,-0.013,0,-0.02 l0.014,-0.414 c0,-0.006,0.001,-0.011,0.001,-0.015 c0.001,-0.004,0.001,-0.008,0.002,-0.01 c0.001,-0.002,0.002,-0.003,0.003,-0.002 c0.001,0.001,0.002,0.003,0.002,0.007 l0.043,0.217 c0.001,0.003,0.001,0.008,0.002,0.014 c0,0.006,0,0.011,0,0.017 m-0.177,0.08 c-0.002,0.019,-0.004,0.037,-0.006,0.053 c-0.006,0.037,-0.014,0.058,-0.023,0.057 c-0.017,0,-0.029,-0.08,-0.027,-0.202 c0.001,-0.056,0.005,-0.107,0.011,-0.145 c0.006,-0.037,0.014,-0.058,0.023,-0.057 c0.012,0,0.022,0.049,0.026,0.12 l0.046,0 c-0.001,-0.104,-0.007,-0.203,-0.018,-0.281 c-0.027,-0.19,-0.075,-0.181,-0.106,0.02 c-0.032,0.2,-0.036,0.517,-0.009,0.707 c0.027,0.19,0.075,0.18,0.106,-0.02 c0.011,-0.073,0.019,-0.16,0.023,-0.251 l-0.046,0,0,0 m-0.198,-0.553 l-0.043,0,0,0,-0.087,0 c-0.012,0,-0.023,0.026,-0.032,0.074 c-0.009,0.048,-0.015,0.116,-0.018,0.191 l-0.006,0.185,-0.039,-0.45,-0.039,0,-0.032,0.922,0.048,0,0.015,-0.435,0.039,0.431,0,0.005,0.039,0,0.019,-0.56 c0.001,-0.023,0.003,-0.043,0.005,-0.057 c0.003,-0.015,0.006,-0.023,0.01,-0.023 l0.041,0,-0.022,0.64,0.048,0,0.022,-0.64,0.045,0,0.01,-0.283,-0.024,0,0,-0.001 m-0.338,0 l-0.016,0.461 c-0.004,0.121,-0.013,0.202,-0.028,0.202 c-0.013,0,-0.02,-0.074,-0.015,-0.202 l0.016,-0.461,-0.048,0,-0.016,0.461 c-0.01,0.296,0.016,0.5,0.061,0.5 c0.045,0,0.067,-0.213,0.077,-0.5 l0.016,-0.461,-0.048,0 m-0.119,0.284 l0.01,-0.283,-0.069,0 c-0.012,0,-0.023,0.026,-0.032,0.074 c-0.009,0.048,-0.015,0.116,-0.018,0.191 l-0.023,0.657,0.048,0,0.007,-0.214,0.045,0,0.009,-0.25,-0.045,0,0.006,-0.174,0.062,0"></path>
        </clipPath>
      </svg>

      <svg className="svg_hidden">
        {/* <clipPath id="btn-stroke" clipPathUnits="objectBoundingBox">
          <path d="M0.053,0.016 C0.033,0.03,0.013,0.104,0.005,0.189 C0.001,0.231,0.001,0.236,0.001,0.508 C0.001,0.78,0.001,0.784,0.005,0.826 C0.01,0.88,0.024,0.947,0.035,0.973 C0.04,0.984,0.049,0.996,0.055,1 C0.07,1,0.932,1,0.947,1 C0.953,0.996,0.961,0.984,0.966,0.973 C0.978,0.947,0.992,0.88,0.997,0.826 C1,0.784,1,0.78,1,0.508 C1,0.236,1,0.231,0.997,0.189 C0.992,0.135,0.978,0.068,0.966,0.042 C0.961,0.031,0.953,0.019,0.947,0.015 C0.933,0.005,0.067,0.006,0.053,0.016 M0.958,0.102 C0.968,0.124,0.974,0.15,0.98,0.191 L0.984,0.224,0.984,0.508 L0.984,0.791,0.98,0.824 C0.974,0.865,0.968,0.891,0.958,0.913 L0.951,0.931,0.501,0.931 L0.051,0.931,0.043,0.913 C0.034,0.891,0.028,0.865,0.022,0.824 L0.018,0.791,0.017,0.524 C0.017,0.351,0.017,0.248,0.018,0.229 C0.022,0.173,0.036,0.111,0.049,0.09 C0.053,0.084,0.19,0.082,0.503,0.083 L0.951,0.085,0.958,0.102"></path>
        </clipPath> */}
        <clipPath id="btn-stroke" clipPathUnits="objectBoundingBox">
          <path d="M0.053,0.016 C0.033,0.03,0.013,0.104,0.005,0.189 C0.001,0.231,0.001,0.236,0.001,0.508 C0.001,0.78,0.001,0.784,0.005,0.826 C0.01,0.88,0.024,0.947,0.035,0.973 C0.04,0.984,0.049,0.996,0.055,1 C0.07,1,0.932,1,0.947,1 C0.953,0.996,0.961,0.984,0.966,0.973 C0.978,0.947,0.992,0.88,0.997,0.826 C1,0.784,1,0.78,1,0.508 C1,0.236,1,0.231,0.997,0.189 C0.992,0.135,0.978,0.068,0.966,0.042 C0.961,0.031,0.953,0.019,0.947,0.015 C0.933,0.005,0.067,0.006,0.053,0.016 M0.958,0.082 C0.97,0.106,0.979,0.147,0.985,0.199 L0.989,0.239,0.989,0.508 L0.989,0.776,0.985,0.817 C0.979,0.868,0.97,0.909,0.958,0.934 L0.949,0.953,0.501,0.953 L0.053,0.953,0.043,0.934 C0.032,0.909,0.022,0.868,0.017,0.817 L0.013,0.776,0.013,0.508 L0.013,0.239,0.017,0.199 C0.024,0.128,0.039,0.078,0.055,0.066 C0.059,0.063,0.262,0.061,0.506,0.062 L0.949,0.063,0.958,0.082"></path>
        </clipPath>
      </svg>
      {mobile && (
        <svg className="svg_hidden">
          <clipPath id="btn-stroke-full" clipPathUnits="objectBoundingBox">
            <path d="M0.028,0 H0.972 C0.988,0,1,0.121,1,0.269 V0.731 C1,0.879,0.988,1,0.972,1 H0.028 C0.012,1,0,0.879,0,0.731 V0.269 C0,0.121,0.012,0,0.028,0 M0.028,0.038 C0.015,0.038,0.004,0.142,0.004,0.269 V0.731 C0.004,0.858,0.015,0.962,0.028,0.962 H0.972 C0.985,0.962,0.996,0.858,0.996,0.731 V0.269 C0.996,0.142,0.985,0.038,0.972,0.038 H0.028" />
          </clipPath>
        </svg>
      )}

      <svg className="svg_hidden">
        <clipPath id="funtico_star" clipPathUnits="objectBoundingBox">
          <path d="M0.432,0.043 C0.461,-0.014,0.539,-0.014,0.568,0.043 L0.685,0.279 L0.936,0.322 C0.997,0.333,1,0.411,0.978,0.457 L0.799,0.646 L0.837,0.908 C0.847,0.972,0.783,1,0.727,0.991 L0.5,0.872 L0.273,0.991 C0.217,1,0.153,0.972,0.163,0.908 L0.201,0.646 L0.022,0.457 C-0.021,0.411,0.003,0.333,0.064,0.322 L0.315,0.279 L0.432,0.043"></path>
        </clipPath>
      </svg>
    </>
  );
}
