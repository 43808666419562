import { formatInfo } from "../money/formatInfo.js";
import { BigDecimal } from "../money/index";

/**
 *
 * @param {import("./types.js").CurrencyData[]}  currencies
 * @param {string} code
 * @returns {import("./types.js").Currency}
 */
export function materializeCurrency(currencies, code) {
  const x = currencies.find(
    (c) => c.code.toLowerCase().localeCompare(code.toLowerCase()) === 0
  );
  if (!x) {
    return;
  }
  return makeCurrency(x);
}
/**
 *
 * @param {import("./types.js").CurrencyData} x
 * @returns {import("./types.js").Currency}
 */
export function makeCurrency(x) {
  return {
    ...x,
    // ...formatInfo(x.code),
  };
}

/**
 * Validate the min/max total amount per payment (including fees).
 * @param {import("./types.js").Money} param0
 * @returns {string|undefined} undefined if valid, string with error if invalid
 */
export function validate({ amount, currency }) {
  const { min, max, code } = currency;
  if (!amount) {
    return "Amount is required";
  }
  const amountNumber = new BigDecimal(amount);
  if (
    Boolean(min || max) &&
    (amountNumber.lt(min) || amountNumber.gt(Number(max)))
  ) {
    return `Minimum: ${min} ${code.toUpperCase()}, maximum: ${max} ${code.toUpperCase()}`;
  }
  return undefined;
}
/**
 * Get valid providers for this money.
 * @param {import("./types.js").Money} param0
 * @returns {import("./types.js").CurrencyProviderRange[]} empty array if no valid providers
 */
export function getValidProviders({ amount, currency }) {
  if (validate({ amount, currency })) {
    return [];
  }
  const amountNumber = new BigDecimal(amount);
  return currency.providers.filter(
    ({ min, max }) =>
      (!min || amountNumber.gte(min)) && (!max || amountNumber.lte(max))
  );
}
