import React, { useState } from "react";
import Eye from "/assets/img/icons/eye.svg";

const tx = "text";

export default function Password({
  label,
  handleChange,
  error,
  type,
  isOk,
  tip,
  ...props
}) {
  const [show, setShow] = useState(false);

  const toogleShow = () => {
    setShow(!show);
  };

  return (
    <label
      className={`label password${props.value ? " active" : ""}${
        error ? " error" : ""
      }${props.value && isOk ? " success" : ""}`}
    >
      {tip && (
        <div className="top_tip df ai-c jc-st">
          <p className="fixel_14">{tip.title}</p>

          {tip?.tip && (
            <>
              <div
                className="icon"
                onClick={({ target }) =>
                  target.closest(".icon").classList.toggle("active")
                }
              ></div>
              <p className="info fixel_14">{tip?.tip}</p>
            </>
          )}
        </div>
      )}
      {label || error ? (
        <div
          className="name fixel_14"
          dangerouslySetInnerHTML={{ __html: error || label }}
        ></div>
      ) : null}
      <div className="row">
        <input
          type={show ? tx : type}
          onChange={handleChange}
          {...props}
          maxLength={64}
        />
        <div className="border"></div>
        <div className={`ico df ${show ? "show" : ""}`} onClick={toogleShow}>
          <Eye />
        </div>
      </div>
    </label>
  );
}
