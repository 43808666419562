import useSWR from 'swr'

/**
 *
 * Having the Min/max range for provider.
 * Call any of (rate and best-rate) endpoints must be made .
 * @typedef {import('/components/pop-ups/wallet-parts/components/buy-crypto/types').BestRate[]} Body
 * @typedef {{ 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import('/components/pop-ups/wallet-parts/components/buy-crypto/types').CurrencyProviderRange[]} validProviders
 * @param {import('/components/pop-ups/wallet-parts/components/buy-crypto/types').Currency} currency
 * @param {string} amount
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useEstimatedExchangeResultAll(axiosClient, validProviders, currency, amount, config = {}) {
  return useSWR(
    () => [
      'cashier/crypto/estimated-exchange-result-for-all-providers',
      currency.code,
      amount,
      validProviders.map(({ provider }) => provider)
    ],
    ([url, currency, amount, providers]) =>
      axiosClient.wrap(
        axiosClient.get(url, {
          params: {
            fromCurrencyCode: currency,
            fromCurrencyAmount: amount,
            providers
          }
        })
      ),
    config
  )
}
