import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade";

const body = document.querySelector("body");
const isIOSmobile = detectIOSmobile();

const bodyScrollControl = {
  lastScrollPosition: 0,

  disable(y) {
    this.lastScrollPosition = y ?? window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${this.lastScrollPosition}px`;
  },

  enable() {
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, this.lastScrollPosition);
    window?.bodyScroll?._init();
  },
};

function detectIOSmobile() {
  const userAgent = navigator.userAgent;

  if (/Android/i.test(userAgent)) {
    return false;
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true;
  } else {
    return null;
  }
}

const detectIsMobileDevice = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

// Disable / enable scroll
const stopScroll = (y) => {
  isIOSmobile ? bodyScrollControl.disable(y) : disableBodyScroll(body);
};

const startScroll = () => {
  isIOSmobile ? bodyScrollControl.enable() : enableBodyScroll(body);
};

export { stopScroll, startScroll, detectIsMobileDevice };
