import React, { createContext, useState } from "react";
import { useEffect } from "react";

const AuthContext = createContext({});

const initialState = {
  token: null,
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => {
    // Load auth state from localStorage if it exists
    const authData = localStorage.getItem("auth");

    return JSON.parse(authData) || initialState;
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify({ ...auth }));

    const body = document.documentElement;
    const cursor = auth?.cursor?.defaultStateUrl;
    const hover = auth?.cursor?.hoverStateUrl;
    body.style.setProperty(
      "--def-cursor",
      cursor ? `url("${cursor}"), default` : "default"
    );
    body.style.setProperty(
      "--def-hover-cursor",
      hover ? `url("${hover}"), pointer` : "pointer"
    );

    return () => {};
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
