import useSWRImmutable from 'swr/immutable'

/**
 *
 * Supported currencies and min/max range manually set by the provider, and SHOULD be cached.
 * @typedef {import('/components/pop-ups/wallet-parts/components/buy-crypto/types').Currency[]} Body
 * @typedef {{ 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useCryptoAvailableCurrencies(axiosClient, config = {}) {
  return useSWRImmutable(
    ['cashier/crypto/available-currencies'],
    ([url]) => axiosClient.wrap(axiosClient.get(url)),
    config
  )
}
