import React, { useState } from "react";
import fiat from "../../../assets/img/wallet/buy-fiat.png";
import ctypto from "../../../assets/img/wallet/buy-ctypto.png";
import exchange from "../../../assets/img/wallet/buy-exchange.png";
import Exchange from "./components/Buy-Exchange";
import Fiat from "./components/buy-fiat/Buy-Fiat";
import Crypto from "./components/buy-crypto/Buy-Crypto";

const cryptoExs = [
  { icon: "binance" },
  { icon: "coinbase" },
  { icon: "kraken" },
];

const tabs = [
  { key: "fiat", src: fiat, alt: "  Buy $TICO <br /> with FIAT" },
  { key: "ctypto", src: ctypto, alt: "Buy $TICO <br /> with CRYPTO" },
  { key: "exchange", src: exchange, alt: "Buy $TICO on <br /> an exchange" },
];

const parts = {
  exchange: Exchange,
  fiat: Fiat,
  ctypto: Crypto,
};

export default function Buy(props) {
  const [activePart, setActivePart] = useState("");

  const Component = parts[activePart];

  const changePart = (p = "") => {
    setActivePart(p);
  };

  return Component ? (
    <Component back={changePart} {...props} cryptoExs={cryptoExs} />
  ) : (
    <>
      <p className="text-center">Choose how you would like to buy $TICO:</p>
      <div className="buy-grid">
        {tabs.map(({ key, ...tab }) => (
          <button
            key={key}
            className="buy-grid--tab df fd-c text-center ai-c br-16"
            onClick={() => changePart(key)}
          >
            <div className="df icon cover">
              <img {...tab} />
            </div>
            <span
              className="fixel_16_sbl"
              dangerouslySetInnerHTML={{ __html: tab.alt }}
            ></span>
          </button>
        ))}
      </div>
    </>
  );
}
