let store = {
  _state: {
    basePath: "/",
    opened_popup: { open: false },
    isMobile: window.innerWidth < 1025,
    isPageLoading: true,
    showWallet: false,
    documentTitle: "Funtico",
    pageTitle: null,
    country: "",
    appearance: null,
    simplexWidget: false,
    countries: false,
    socialConnections: false,
  },

  _callSubscriber() {},

  getState() {
    return this._state;
  },

  subscribe(observer) {
    this._callSubscriber = observer;
  },

  setPopUp(type, arr) {
    this._state.opened_popup = {
      // ...this._state.opened_popup,
      ...arr,
      open: type,
    };
    this._callSubscriber(this.getState());
  },
  setDeviceType(width) {
    this._state.isMobile = width;
    this._callSubscriber(this.getState());
  },
  setShowWallet(boo) {
    this._state.showWallet = boo;
    this._callSubscriber(this.getState());
  },
  setAppearance(data) {
    this._state.appearance = data;
    this._callSubscriber(this.getState());
  },
  setCountry(cntr) {
    this._state.country = cntr;
    this._callSubscriber(this.getState());
  },
  setLoading(active) {
    this._state.isPageLoading = active;
    this._callSubscriber(this.getState());
  },
  setPageTitle(title) {
    this._state.pageTitle = title;
    this._callSubscriber(this.getState());
  },
  setSimplexWidget(boo) {
    this._state.simplexWidget = boo;
    this._callSubscriber(this.getState());
  },
  setCountries(c) {
    this._state.countries = c;
    this._callSubscriber(this.getState());
  },
  setSocialConnections(c) {
    this._state.socialConnections = c;
    this._callSubscriber(this.getState());
  },
};

export default store;
