import { lazy } from "react";

/**
 * @type {{
    path: string;
    component:React.LazyExoticComponent<({ state, to, title }: {
        state: any;
        to?: string;
        title?: string;
    }) => React.JSX.Element>;
    temp: string;
    featureFlag?: string | undefined;
    subpage?: {
    path: string;
    component:React.LazyExoticComponent<({ state, to, title }: {
        state: any;
        to?: string;
        title?: string;
    }) => React.JSX.Element>;
    temp: string;
    featureFlag?: string | undefined;
    }[] | undefined;
}[]}
 */
const pages = [
  {
    path: "*",

    component: lazy(() => import("./views/404/404.jsx")),
    temp: "404",
  },
  {
    path: "/",

    component: lazy(() => import("./views/home/Home.jsx")),
    temp: "Home",
  },
  // { path: "/about-us", temp: "About" },
  {
    path: "/games/:slug",

    component: lazy(() => import("./views/game/Game.jsx")),
    temp: "Game",
  },
  {
    path: "/marketplace",

    component: lazy(() => import("./views/market/Market.jsx")),
    temp: "Market",
  },
  {
    path: "/shop",

    component: lazy(() => import("./views/shop/")),
    temp: "Shop",
  },
  {
    path: "/marketplace/:slug",

    component: lazy(() => import("./views/asset/Asset.jsx")),
    temp: "Asset",
  },
  {
    path: "/reset-password",

    component: lazy(() => import("./views/reset/Reset.jsx")),
    temp: "Reset",
  },
  {
    path: "/backpack",

    component: lazy(() => import("./views/backpack/Backpack.jsx")),
    temp: "Backpack",
  },
  {
    path: "/tournaments",

    component: lazy(() => import("./views/tournaments/Tournaments.jsx")),
    temp: "Tournaments",
  },
  {
    path: "/community-leaderboard",
    temp: "Community",
    component: lazy(() => import("./views/community/Community.jsx")),
    featureFlag: "communities-leaderboard",
  },
  {
    path: "/loot-packs",
    component: lazy(() => import("./views/lootpacks/LootPacks.jsx")),
    temp: "LootPacks",
  },
  {
    path: "/tournaments/:slug",
    component: lazy(() => import("./views/tournament/Tournament.jsx")),
    temp: "Tournament",
    // subpage: [{ path: "leaderboard", temp: "Leaderboard" }],
  },
  {
    path: "/promotions",

    component: lazy(() => import("./views/promotions/Promotions.jsx")),
    temp: "Promotions",
  },
  {
    path: "/loyalty",

    component: lazy(() => import("./views/loyalty/Loyalty.jsx")),
    temp: "Loyalty",
  },
  {
    path: "/faq",
    component: lazy(() => import("./views/faq/FAQ.jsx")),
    temp: "FAQ",
  },
  {
    path: "/terms-and-conditions",
    component: lazy(() => import("./views/terms/Terms.jsx")),
    temp: "Terms",
  },
  {
    path: "/privacy-policy",

    component: lazy(() => import("./views/policy/Policy.jsx")),
    temp: "Policy",
  },
  {
    path: "/scratch-cards",

    component: lazy(() => import("./views/scratch/Scratch.jsx")),
    temp: "Scratch",
  },
  {
    path: "/capsules",

    component: lazy(() => import("./views/capsules/Capsules.jsx")),
    temp: "Capsules",
  },
  {
    path: "/lucky-funatic",

    component: lazy(() => import("./views/lucky/Lucky.jsx")),
    temp: "Lucky Funatic",
  },
  // Not yet ready to be revealed
  // {
  //   path: "/tico",

  //   component: lazy(() => import("./views/tico/Tico.jsx")),
  //   temp: "Tico",
  // },
  {
    path: "/wheel-of-fortune",
    component: lazy(() => import("./views/wheel/Wheel.jsx")),
    temp: "Wheel",
    featureFlag: "wheel-of-fortune",
  },
  {
    path: "/profile",
    temp: "Profile",
    component: lazy(() => import("./views/profile/Profile.jsx")),
    subpage: [
      {
        path: "settings",

        component: lazy(() => import("./views/profile/profile/Settings.jsx")),
        temp: "Settings",
      },
      {
        path: "verification",
        component: lazy(
          () => import("./views/profile/profile/Verification.jsx")
        ),
        temp: "Verification",
        featureFlag: "identity-verification",
      },
      {
        path: "rank-and-progress",

        component: lazy(() => import("./views/profile/profile/Progress.jsx")),
        temp: "Progress",
        featureFlag: "ranks-and-seasons",
      },
      {
        path: "history",
        component: lazy(() => import("./views/profile/profile/History.jsx")),
        temp: "History",
      },
      {
        path: "connected-accounts",
        component: lazy(
          () => import("./views/profile/profile/ConnectedAccounts.jsx")
        ),
        temp: "Connected Accounts",
        featureFlag: "social-connect",
      },
      {
        path: "appearance",
        component: lazy(() => import("./views/profile/profile/Appearance.jsx")),
        temp: "Appearance",
      },
    ],
  },
];

function getRoutes(allPages) {
  function getPageRoute({ subpage, component, ...page }) {
    const child = subpage?.map(({ path, component, ...rest }) => {
      return {
        path,
        component,
        ...rest,
      };
    });

    return { ...page, component, children: child };
  }

  const routes = allPages.reduce((acc, page) => {
    acc.push(getPageRoute(page));

    return acc;
  }, []);

  return routes;
}

export const routes = getRoutes(pages);
