import React, { useEffect, useState } from "react";
import { formatUserDate } from "/utils/utils";
import Calendar from "react-calendar";

let sto = null;

export default function MyDate({
  label,
  handleChange,
  error,
  tip,
  def,
  type,
  name,
  ...props
}) {
  const [shovedValue, setShovedValue] = useState(formatUserDate(def));
  const [openCalendar, setOpenCalendar] = useState(false);

  const onChange = (el, e) => {
    e.preventDefault();
    setOpenCalendar(false);
    const toISO = new Date(`${el}`.split("GMT")[0] + " GMT+0000").toISOString();
    handleChange({ target: { name, value: toISO } });
  };

  const onBodyClick = ({ target }) => {
    sto && clearTimeout(sto);
    sto = setTimeout(() => {
      if (!target.closest(".label.date")) setOpenCalendar(false);
    }, 100);
  };

  useEffect(() => {
    props.value && setShovedValue(formatUserDate(props.value));
    return () => {};
  }, [props.value]);

  useEffect(() => {
    openCalendar && document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, [openCalendar]);

  return (
    <>
      <label
        className={`label date ${props.value ? "active" : ""} ${
          error ? "error" : ""
        } `}
      >
        {tip && (
          <div className="top_tip df ai-c jc-st">
            <p className="fixel_14">{tip.title}</p>

            {tip?.tip && (
              <>
                <div
                  className="icon"
                  onClick={({ target }) =>
                    target.closest(".icon").classList.toggle("active")
                  }
                ></div>
                <p className="info fixel_14">{tip?.tip}</p>
              </>
            )}
          </div>
        )}
        {label || (error && error?.length >= 1) ? (
          <div
            className="name fixel_14"
            dangerouslySetInnerHTML={{ __html: error || label }}
          ></div>
        ) : null}
        <div className="row" onClick={() => setOpenCalendar(true)}>
          <input {...props} value={shovedValue} readOnly />
          <input
            type="date"
            className="hidden"
            name={name}
            value={props.value?.split("T")[0]}
            readOnly
          />
          <div className="border"></div>
        </div>

        {openCalendar && (
          <Calendar
            onClickDay={onChange}
            value={new Date(props?.value || def || new Date())}
          />
        )}
      </label>
    </>
  );
}
