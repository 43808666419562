import React, { useContext, useEffect, useState } from "react";

import useForm from "/utils/hooks/useForm";
import CustomButton from "/components/core/ButtonDefault";
import FormLabels from "/components/core/labels/Form";
import AuthContext from "/context/AuthProvider";

const labels = [
  {
    placeholder: "Enter your e-mail",
    type: "email",
    name: "email",
    spaces: true,
  },
  {
    placeholder: "Enter your password",
    type: "password",
    name: "password",
  },
  {
    title: "Remember me",
    type: "checkbox",
    name: "remember",
  },
];

export default function Login({ state }) {
  const useform = useForm({}, "auth/login");
  const { isSuccess, isSending, formResponse, setInvalidFields, handleSubmit } =
    useform;
  const { setAuth } = useContext(AuthContext);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isSuccess) return;
    setAuth({ ...formResponse });
    state.setPopUp("");
    return () => {};
  }, [isSuccess]);

  useEffect(() => {
    const err = formResponse?.errors;

    if (err && Array.isArray(err)) {
      setInvalidFields((e) => ({ ...e, email: err[0] }));
    } else {
      const s = formResponse?.success;
      const m = formResponse?.message;
      !s && m && setError(m);
    }
    return () => {};
  }, [formResponse]);

  return (
    <>
      <div className="title">
        <p className="t fixel_36">Sign in</p>
      </div>

      <div className="content_data df fd-c">
        {/* <EnterViaSocials state={state} />
        <div className="or df ai-c fixel_16_sbl">OR</div> */}

        <form className="form df fd-c" onSubmit={handleSubmit}>
          <div className="form_content">
            {error && (
              <p className="fixel_22 text-center color-red w-full">{error}</p>
            )}
            <FormLabels {...useform} labels={labels} />

            <button
              type="button"
              className="def wt"
              onClick={(e) => state.setPopUp("reset")}
            >
              Forgot your password?
            </button>
          </div>
          <CustomButton
            classname="submit pr as-c"
            def={40}
            type="submit"
            title="Sign in"
            disabled={isSending}
          />
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">Not a member?</p>
        <button className="def wt" onClick={(e) => state.setPopUp("reg")}>
          Sign up now
        </button>
      </div>
    </>
  );
}
