import React, { useRef } from "react";
import DelayLink from "/components/core/DelayLink";
import { useLocation } from "react-router-dom";
import UserRanks from "/components/core/UserRanks";
import RankLine from "/components/core/RankLine";
import UserAvatar from "/components/core/UserAvatar";
import InfoMessage from "/components/core/InfoMessage";
import { useCashedKycStatus } from "/api/user/kyc-status";
import VerificationBadge from "/assets/img/icons/verified.svg";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export default function User({ email, shortInfo, mobile, auth, info, state }) {
  const { pathname } = useLocation();
  const isProfile = pathname.includes("profile");
  const { rank } = auth;
  const hoverRef = useRef(null);
  const verificationRef = useRef(null);
  const kycStatus = useCashedKycStatus();
  const cb = isProfile ? { onClick: (e) => {} } : {};
  const isIdentityOn = useFeatureIsOn("identity-verification");

  return (
    <div className="user_info df">
      <DelayLink to={"/profile/appearance"} className="df" {...cb}>
        <UserAvatar {...auth}></UserAvatar>
      </DelayLink>

      <div className="user_info_about">
        <DelayLink
          to={"/profile/settings"}
          className={`name fixel_16_sbl ${kycStatus?.kyc_status !== "approved" && "unverified"}`}
          myRef={verificationRef}
          {...cb}
        >
          {auth?.username}
          {isIdentityOn && <VerificationBadge />}
        </DelayLink>
        {!mobile && isIdentityOn && (
          <InfoMessage target={verificationRef}>
            <span className="fixel_14">
              {kycStatus?.kyc_status === "approved"
                ? "Your profile is verified"
                : "Your profile is not verified"}
            </span>
          </InfoMessage>
        )}
        {email ? <p className="email fixel_14 ">{auth?.email}</p> : null}
        <div ref={hoverRef}>
          <DelayLink
            to={"/profile/rank-and-progress"}
            {...cb}
            className="user_info_rang df ai-c mr-t-10"
          >
            {rank?.current?.iconUrl && (
              <div className="ico from df cover">
                <img src={rank.current.iconUrl} alt="My Rank Icon" />
              </div>
            )}
            {rank && <RankLine rank={rank} state={state} />}
            {rank?.next?.iconUrl && (
              <div className="ico to df cover">
                <img src={rank.next.iconUrl} alt="Next Rank Icon" />
              </div>
            )}
          </DelayLink>
        </div>
      </div>

      {shortInfo && !mobile && (
        <InfoMessage target={hoverRef}>
          <UserRanks />
        </InfoMessage>
      )}
    </div>
  );
}
