import BigDecimal from "big.js";

// max is 18 decimals
BigDecimal.NE = 18;
// but use .toFixed(currency.decimals) for money
const BigDecimalPrec = {
  // down = 0
  // use half_up for money
  half_up: 1,
  // x = new Big('9876.54321')
  // x.prec(2)                 // '9900'
  // x.prec(7)                 // '9876.543'
  // x.prec(20)                // '9876.54321'
  // x.prec(1, down)           // '9000'
  // x.prec(1, half_up)        // '10000'
  // x                         // '9876.54321'
};
export { BigDecimal, BigDecimalPrec };
