import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";

import FunticoLogo from "/components/core/Logo";
import UnLoginedButton from "/components/core/HeaderNavButtons";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";
import DelayLink from "/components/core/DelayLink";
import Eye from "/assets/img/icons/eye.svg";
import EyeHide from "/assets/img/icons/eye-hide.svg";
import Wallet from "/assets/img/icons/wallet.svg";

import UserAvatar from "/components/core/UserAvatar";
import CustomButton from "/components/core/ButtonDefault";
gsap.registerPlugin(ScrollTrigger);

const CashierWidget = ({ state, isWidgetActive, isMobile }) => {
  const props = {
    title: isMobile ? null : "wallet",
    def: isMobile ? 24 : 40,
    disabled: !isWidgetActive,
    onClick: () => state.setShowWallet(true),
    classname: "pr wallet",
  };

  return <CustomButton {...props}>{isMobile && <Wallet />}</CustomButton>;
};

export default function Header({ isMobile, openNav, state, isWidgetActive }) {
  const { auth } = useContext(AuthContext);
  const headerRef = useRef(null);
  const [balance, setBalance] = useState(false);
  const { pathname } = useLocation();
  const isProfile = pathname.includes("profile");

  useEffect(() => {
    const h = headerRef?.current;
    if (!h) return;
    window["header"] = h;
    setTimeout(createScrollTrigger, 10);
    const resize = new ResizeObserver(() => ScrollTrigger.refresh());
    resize.observe(document.body);
    return () => {
      resize.disconnect();
    };
  }, []);

  const createScrollTrigger = () => {
    const header = headerRef.current;

    ScrollTrigger.create({
      // markers: true,
      trigger: document.body,
      start: `top -5px`,
      // start: `top ${header.clientHeight * -1}px`,
      end: "bottom center",
      onToggle: (e) => header?.classList.toggle("blurred", e.isActive),
    });

    // return { s, ro };
  };
  return (
    <>
      <header className="header" ref={headerRef}>
        <FunticoLogo />

        {!isMobile ? (
          <UnLoginedButton state={state} auth={auth}>
            <CashierWidget {...{ state, isWidgetActive, isMobile }} />
          </UnLoginedButton>
        ) : (
          <>
            <button
              className="mobile_open br-16 def grey"
              onClick={() => openNav(true)}
            >
              <div className="b df fd-c jc-c">
                <span></span>
                <span></span>
              </div>
            </button>

            {auth.token ? (
              isProfile ? (
                <NavLink to={"/profile"} title="Profile" end={true}>
                  <UserAvatar {...auth}>
                    {/* <div className="info"></div> */}
                  </UserAvatar>
                </NavLink>
              ) : (
                <DelayLink to={"/profile"} title="Profile">
                  <UserAvatar {...auth}>
                    {/* <div className="info"></div> */}
                  </UserAvatar>
                </DelayLink>
              )
            ) : null}
          </>
        )}
      </header>

      {isMobile && auth?.token ? (
        <div className="fixed_ballance df jc-sb ai-c">
          <button
            className={`def check br-12 ${!balance ? "" : "hide"}`}
            onClick={(e) => setBalance(!balance)}
          >
            {!balance ? <Eye /> : <EyeHide />}
          </button>
          {balance ? (
            <div className="bal df ai-c">
              <div className="tico_icon ico"></div>
              {auth?.ticoBalance}
            </div>
          ) : null}
          <CashierWidget {...{ state, isWidgetActive, isMobile }} />
        </div>
      ) : null}
    </>
  );
}
