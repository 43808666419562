import useSWR, { useSWRConfig } from "swr";
/**
 *
 * @return {null | string | undefined} Tico balance
 */
export function useCashedKycStatus() {
  return useSWRConfig().cache.get('@"user/kyc-status",')?.data;
}
/**
 * @typedef {{  kyc_status: string }} Body
 * @typedef {{ }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 *
 * @typedef {import("swr").SWRResponse<Body, Error>} UseKycStatus
 * @return {UseKycStatus}
 */
export function useKycStatus(axiosClient, config = {}) {
  return useSWR(
    () => ["user/kyc-status"],
    ([url]) => axiosClient.get(url).then((res) => res.data),
    {
      keepPreviousData: true,
      ...config,
    }
  );
}
export const KYCStatus = {
  NOT_STARTED: "not_started",
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  INCOMPLETE: "incomplete",
  EXPIRED: "expired",
  UNDER_REVIEW: "under_review",
  RESUBMISSION_REQUESTED: "resubmission_requested",
  ON_HOLD: "on_hold",

  /*
   * For any other status that is not listed above.
   */
  UNKNOWN: "unknown",
  isUserVerified(status) {
    return status === this.APPROVED;
  },
  isBanned(status) {
    return status === this.REJECTED;
  },
  getLabelFor(status) {
    return {
      not_started: "Your application is not started yet",
      pending: "Your application is pending",
      approved: "Your application is approved",
      rejected: "Your application is rejected",
      incomplete: "Your application is incomplete",
      expired: "Your application is expired",
      under_review: "Your application is under review",
      resubmission_requested: "Resubmission requested",
      on_hold: "Your application is on hold",
    }[status];
  },
};
