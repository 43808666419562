import useSWRMutation from 'swr/mutation'

/**
 * @typedef {{ }} Data
 * @typedef {{ fromCurrencyCode: string, fromCurrencyAmount: string, provider: string }} ExtraArg
  @typedef {{ 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr/mutation").SWRMutationConfiguration<Data, Error>} config
 * @return {import("swr/mutation").SWRMutationResponse<Data, Error, unknown, ExtraArg>}
 */
export function useCreatePaymentSession(axiosClient, config) {
  return useSWRMutation(
    [`cashier/crypto/providers`],
    ([url], { arg: { fromCurrencyCode, fromCurrencyAmount, provider } }) =>
      axiosClient.wrap(
        axiosClient.post(`${url}/${provider}/create-payment-session`, {
          paymentUuid: window.uuid,
          provider,
          fromCurrencyCode,
          fromCurrencyAmount
        })
      ),
    config
  )
}
